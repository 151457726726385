.header_top[data-v-440b000a] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.w145[data-v-440b000a] {
  width: 145px !important;
}
.w173[data-v-440b000a] {
  width: 173px !important;
}
.w390[data-v-440b000a] {
  width: 390px !important;
}
.w880[data-v-440b000a] {
  width: 880px;
}
.el-form--inline .el-form-item[data-v-440b000a] {
  margin-right: 0px;
}
.ml0[data-v-440b000a] {
  margin-left: 0px;
}
.ml-mr-0[data-v-440b000a] {
  margin: 0;
}
[data-v-440b000a] .el-card__header {
  padding: 10px 20px;
}
.record[data-v-440b000a] {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
}
.record[data-v-440b000a]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
[data-v-440b000a] .el-upload-dragger {
  padding: 10px !important;
}